import React from "../hooks/reactImport"
import https from "../services/https"
import { UiObjectInstanceIterator } from "../components/Builder"
import { ProviderAPI } from "../components/PageStateContext"
import config, { API_URL } from "../primeagile.config"
import PrimeAgileHelmet from "../components/Helmet"
import FontSchemeManager from "../components/FontScheme/index"
import { Head } from "../components/Builder/Head"
import { Suspense, useEffect, useRef, useState } from "../hooks/stateHooks"
import { styledCompoent } from "../styledComponent"
import { ApolloClient, ApolloProvider, InMemoryCache } from "../hooks/apollo"
import { setNotificationRef } from "../components/Builder/Components/Notification/Notify"
import NotificationManager from "../components/Builder/Components/Notification/NotificationManager"

export default function Preview(props) {
  const {
    location: { hash },
  } = props

  let pageId = Number(hash?.split("#")[2])
  let locale = hash?.split("#")[1]
  const [state, setState] = useState({
    page: {},
    pageData: {},
    object: [],
    websiteVariables: {},
    isLoading: false,
    error: "",
    layotcss: "",
  })

  useEffect(() => {
    if (!pageId) return

    const link = `${API_URL}/open/cms/page?page=0&size=20&pageId=${pageId}${
      locale ? `&locale=${locale}` : ""
    }&client=${process.env.GATSBY_BUILDSITE}`
    const webURL = `${API_URL}/open/website/by_name/${process.env.GATSBY_BUILDSITE}?client=${process.env.GATSBY_BUILDSITE}`

    setState(p => ({
      ...p,
      isLoading: true,
      error: "",
    }))
    https(
      webURL,
      "get",
      undefined,
      undefined,
      websiteresponse => {
        let layoutSet = websiteresponse?.data?.layoutSet?.layoutSetId
        let websiteVariables = websiteresponse?.data?.jsonData

        setState(p => ({
          ...p,
          websiteVariables: websiteVariables,
        }))
        https(
          link,
          "get",
          undefined,
          undefined,
          pageDataresponse => {
            let data = pageDataresponse?.data?.content?.map(item => {
              return {
                ...item,
                title: item?.title?.phrase,
                metaTitle: item?.metaTitle?.phrase,
              }
            })

            let page = data && data[0]
            setState(p => ({
              ...p,
              pageData: {
                ...page,
                captchaSiteKey: websiteresponse?.data?.captchaSiteKey,
                favicon: websiteresponse?.data.favicon,
                fontScheme: websiteresponse?.data.fontScheme,
                googleAnalyticsCode: websiteresponse?.data.googleAnalyticsCode,
                // layoutSetCss: websiteresponse?.data?.layoutSet?.css?.fileUrl,
                locale: websiteresponse?.data?.defaultLocale,
                locales: websiteresponse?.data?.locales,
                websiteJavascript: websiteresponse?.data?.js?.fileUrl,
                websiteVariables: websiteVariables,
                websiteCSS: websiteresponse?.data?.css?.fileUrl,
              },
            }))
            // ${locale ? `&locale=${locale}` : ""}
            const layoutURL = `${API_URL}/open/ui/layout-set/layout/withTree?layoutSetId=${layoutSet}&objectDataDefinitionId=${
              page.objectDataDefinitionId
            }${locale ? `&locale=${locale}` : ""}&client=${
              process.env.GATSBY_BUILDSITE
            }`
            https(
              layoutURL,
              "get",
              undefined,
              undefined,
              layoutresponse => {
                const layoutTree =
                  layoutresponse?.data?.uiObjectWithChildTreeR
                    ?.uiObjectChildTree

                const layotcss =
                  layoutresponse?.data?.uiObjectWithChildTreeR?.jsonData?.css
                    ?.fileUrl

                setState(p => ({
                  ...p,
                  object: layoutTree,
                  isLoading: false,
                  error: "",
                  layotcss,
                }))
              },
              error => {
                setState(p => ({
                  ...p,
                  isLoading: false,
                  error: "something went wrong building this page",
                }))
              }
            )
          },
          error => {
            setState(p => ({
              ...p,
              isLoading: false,
              error: "something went wrong building this page",
            }))
          }
        )
      },
      error => {
        setState(p => ({
          ...p,
          isLoading: false,
          error: "something went wrong building this page",
        }))
      }
    )
  }, [pageId])

  const ErrorBoundary = React.lazy(() =>
    import("../components/ErrorBoundary/index").then(module => {
      return { default: module.default }
    })
  )

  const client = new ApolloClient({
    uri: `${config.API_URL}/graphql`,
    headers: {
      "X-TENANT-ID": `${process.env.GATSBY_BUILDSITE}`,
    },
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            pages: {
              pages: {
                keyArgs: [],
                merge(existing, incoming, { args: { offset = 0 } }) {
                  // Slicing is necessary because the existing data is
                  // immutable, and frozen in development.
                  const merged = existing ? existing.slice(0) : []
                  for (let i = 0; i < incoming.pages.length; ++i) {
                    merged[offset + i] = incoming[i]
                  }
                  return merged
                },
              },
            },
          },
        },
      },
    }),
  })
  const notificationRef = useRef()

  useEffect(() => {
    setNotificationRef(notificationRef)
  }, [notificationRef])
  if (state.isLoading) {
    return (
      <PageConatiner>
        <h1>Please wait while we build preview Page</h1>
      </PageConatiner>
    )
  }

  if (state.error) {
    return (
      <PageConatiner>
        <h1>Error building preview Page</h1>
      </PageConatiner>
    )
  }

  if (state.pageData?.pageId) {
    return (
      <ApolloProvider client={client}>
        <Suspense fallback={<div />}>
          <NotificationManager ref={notificationRef} />
          <ErrorBoundary>
            <ProviderAPI value={state.pageData} id="primeagile__entry">
              <FontSchemeManager fontScheme={state?.pageData?.fontScheme}>
                <Head
                  pageContext={state.pageData}
                  pageCss={state.pageData?.cssFileReference?.fileUrl}
                  LayoutCSS={state.layotcss}
                />
                <PrimeAgileHelmet
                  pageContext={state.pageData}
                  pageCss={state.pageData?.cssFileReference?.fileUrl}
                  LayoutCSS={state.layotcss}
                />
                {Array.isArray(state.object) &&
                  state.object.map(node => {
                    return (
                      <UiObjectInstanceIterator
                        page={state.page}
                        object={node}
                        pageData={state.pageData}
                        websiteVariables={state.websiteVariables}
                      />
                    )
                  })}
              </FontSchemeManager>
            </ProviderAPI>
          </ErrorBoundary>
        </Suspense>
      </ApolloProvider>
    )
  } else return <>preview</>
}

const PageConatiner = styledCompoent.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`

import React from "../../hooks/reactImport"
import { createGlobalStyle } from "styled-components"

export default function FontSchemeManagement({ fontScheme, children }) {
  const GlobalStyles = fontScheme
    ? createGlobalStyle`
/* 
 @font-face {
   font-family: ${fontScheme.defaultFontFamily};
   src: local('Pacifico Regular'), local('Pacifico-Regular'), url(https://fonts.gstatic.com/s/pacifico/v12/FwZY7-Qmy14u9lezJ-6H6MmBp0u-.woff2) format('woff2');
    

} */
//  * {
//    font-family: ${fontScheme.defaultFontFamily};
//    font-display: swap;
//  }
/* @font-face {
  font-family: 'Fibonaccy';
  src: URL('https://images.primeagile.com/diydiaries_com/application/10953/fibonaccy-journey.ttf') format('truetype');
} */
  html {
    --color-text: black;
    --color-background: white;
    --color-primary: rebeccapurple;
    
  }

  body {
    font-family:  sans-serif;
  }
  h1 {
    font-size: ${fontScheme?.h1FontSize}px;
    color: #${fontScheme?.h1FontColor};
    font-family: ${fontScheme?.h1FontFamily}, sans-serif;
  }
  h2 {
  font-size: ${fontScheme?.h2FontSize}px;
    color: #${fontScheme?.h2FontColor};
    font-family: ${fontScheme?.h2FontFamily}, sans-serif;
  }
  h3 {
  font-size: ${fontScheme?.h3FontSize}px;
    color: #${fontScheme?.h3FontColor};
    font-family: ${fontScheme?.h3FontFamily}, sans-serif;
  }
  h4 {
  font-size: ${fontScheme?.h4FontSize}px;
    color: #${fontScheme?.h4FontColor};
    font-family: ${fontScheme?.h4FontFamily}, sans-serif;
  }
  h5 {
  font-size: ${fontScheme?.h5FontSize}px;
    color: #${fontScheme?.h5FontColor};
    font-family: ${fontScheme?.h5FontFamily}, sans-serif;
  }

  h6 {
    font-family: ${fontScheme?.h6FontFamily}, sans-serif;
  }

  .errorInput {
    border: 1px solid red !important;
  }

  .slick-next:before, .slick-prev:before{
    font-family: ${fontScheme?.defaultFontFamily} !important;
    font-display: swap;
  }
`
    : createGlobalStyle`
    .errorInput {
      border: 1px solid red !important;
    }

  .slick-next:before, .slick-prev:before{
    font-family: ${fontScheme?.defaultFontFamily} !important;
    font-display: swap;
  }
    `
  return (
    <React.Fragment>
      <GlobalStyles />
      {children}
    </React.Fragment>
  )
}
